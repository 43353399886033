import React from "react";

class Program extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    document.title =
      "ICITA 2025 | Programme - 19th International Conference on Information Technology and Applications (ICITA)";
  }

  render() {
    const { data } = this.state;
    return (
      <div>
        <div className="intro">
          <div className="container">
            <div className="row">
              <div className="col text-center">
                <div className="section_title_container text-center">
                  <div className="section_title">
                    <h1>Programme </h1>
                  </div>
                </div>
                <div className="intro_text text-center">
                  <div className="row speakers_row">
                    Please note, that sessions on 14th - 16th October 2025 will
                    be held in person at <br></br>
                    <h4>Gaustadalléen 21,NO-0349 Oslo, Norway </h4>
                    <br></br>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2458.9516134789596!2d10.716390199999998!3d59.9423288!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46416de89ed45643%3A0x5d181fa4b8b32b29!2sGaustadall%C3%A9en%2021a%2C%200349%20Oslo%2C%20Norway!5e1!3m2!1sen!2sae!4v1730551949737!5m2!1sen!2sae"
                      width="600"
                      height="450"
                      style={{ border: "0" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                    <br></br>
                  </div>
                  {/* <div className="row speakers_row">
                    <a
                      href="https://www.icita.world/files/ICITA_2025.pdf"
                      target="_blank"
                    >
                      Click here to access the programme for ICITA 2025
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Program;
